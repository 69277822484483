<template>
  <div>
    <Form ref="form" :model="formData">
      <FormItem>
        <Input v-model="formData.target" type="textarea" :rows="30" placeholder="格式: ip:端口, 每行一个ip" />
      </FormItem>
      <FormItem>
        <Button type="primary" @click="handleSubmit()" long>更 新</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import {put, get} from "@/api/http";
import Notice from "@/components/baseNotice";
export default {
  name: "tcpmonitor",
  data() {
    return {
      formData: {
        target: ""
      },
    }
  },
  methods: {
    getTagets() {
      get("/api/v1/tx")
          .then((resp)=>{
            const code = resp.code
            if (code === 0) {
              if (resp.data.length > 0) {
                this.formData.target = resp.data[0].target
              }
            }
          })
    },
    handleSubmit() {
      put("/api/v1/tx", this.formData)
        .then(resp=>{
          const code = resp.code
          if (code === 0) {
            this.getTagets()
            Notice.success("更新操作", "更新成功")
          } else {
            Notice.error("更新操作", resp.data.msg)
          }
        })
    }
  },
  mounted() {
    this.getTagets()
  }
}
</script>

<style scoped>

</style>